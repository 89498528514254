import { FC, Suspense } from "react";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../assets/ts/_utils/DomHelpers";
import { WithChildren } from "../helpers/react18MigrationHelpers";

const SuspensedView: FC<WithChildren> = ({ children }) => {
    const baseColor = getCSSVariableValue("--bs-info");
    TopBarProgress.config({
        barColors: {
            "0": baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    });
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export default SuspensedView;
