import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
    getAdministrationAegis,
    getAdministrationAegises,
    AdministrationAegisesInterface,
    AdministrationAegisInterface,
} from "../modules/company-administration/aegises/_requests";

export interface AdministrationAegisesReducerInterface {
    aegises: AdministrationAegisesInterface;
    currentAegis: AdministrationAegisInterface | undefined;
    error: AxiosError | undefined;
    loading: boolean;
}

const initialState: AdministrationAegisesReducerInterface = {
    aegises: {
        active: [],
        inactive: [],
    },
    currentAegis: undefined,
    error: undefined,
    loading: false,
};

export const getAdministrationAegisesAction = createAsyncThunk(
    "administrationAegises/getAdministrationAegises",
    async (obj: { company: string }, thunkAPI) => {
        try {
            const result = await getAdministrationAegises(obj.company);
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

export const getAdministrationAegisAction = createAsyncThunk(
    "administrationAegises/getAdministrationAegis",
    async (obj: { company: string; aegis: number }, thunkAPI) => {
        try {
            const result = await getAdministrationAegis(obj.company, obj.aegis);
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

const administrationAegisesSlice = createSlice({
    name: "administrationAegis",
    initialState,
    reducers: {
        resetState() {
            return { ...initialState };
        },
        setError: (state, action: PayloadAction<AxiosError>) => {
            return { ...state, error: action.payload };
        },
        setCurrentAegis: (state, action: PayloadAction<AdministrationAegisInterface>) => {
            return { ...state, currentAegis: action.payload };
        },
    },
    extraReducers: (builder) => {
        // getAdministrationAegisesAction
        builder.addCase(getAdministrationAegisesAction.pending, (state) => {
            return { ...state, aegises: initialState.aegises, error: undefined, loading: true };
        });
        builder.addCase(getAdministrationAegisesAction.fulfilled, (state, action) => {
            return { ...state, aegises: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getAdministrationAegisesAction.rejected, (state, action) => {
            return { ...state, aegises: initialState.aegises, error: action.payload as AxiosError, loading: false };
        });
        // getAdministrationAegisAction
        builder.addCase(getAdministrationAegisAction.pending, (state) => {
            return { ...state, currentAegis: initialState.currentAegis, error: undefined, loading: true };
        });
        builder.addCase(getAdministrationAegisAction.fulfilled, (state, action) => {
            return { ...state, currentAegis: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getAdministrationAegisAction.rejected, (state, action) => {
            return {
                ...state,
                currentAegis: initialState.currentAegis,
                error: action.payload as AxiosError,
                loading: false,
            };
        });
    },
});

export const { resetState, setError, setCurrentAegis } = administrationAegisesSlice.actions;

export default administrationAegisesSlice.reducer;
