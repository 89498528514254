import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { getAccessTokenAndCookie, logoutUser, refreshAccessToken } from "../modules/auth/core/_requests";
import { UserModel, UserPreferencesInterface } from "../modules/auth/core/_models";
import { getUser, getUserPreferences, updateEmail } from "../modules/accounts/_requests";

const initialState: UserModel = {
    email: "",
    first_name: "",
    last_name: "",
    user_preferences: {
        theme: "light",
        language: "en",
        exposure_unit: "a8",
    },
    access: [],
    session_preferences: {
        selected_company: "",
        selected_site_location: -1,
    },
};

export const getCurrentUserAction = createAsyncThunk("userInfo/getCurrentUser", async (_: void, thunkAPI) => {
    try {
        return (await getUser()) as UserModel;
    } catch (err) {
        return thunkAPI.rejectWithValue(err as AxiosError);
    }
});

export const getUserPreferencesAction = createAsyncThunk(
    "userInfo/getUserPreferencesAction",
    async (_: void, thunkAPI) => {
        try {
            return (await getUserPreferences()) as UserPreferencesInterface;
        } catch (err) {
            return thunkAPI.rejectWithValue(err as AxiosError);
        }
    },
);

interface LoginDetails {
    email: string;
    password: string;
}

export const loginAction = createAsyncThunk("userInfo/login", async (loginDetails: LoginDetails, thunkAPI) => {
    try {
        await getAccessTokenAndCookie(loginDetails.email, loginDetails.password);
        return await thunkAPI.dispatch(getCurrentUserAction());
    } catch (err) {
        return thunkAPI.rejectWithValue(err as AxiosError);
    }
});

export const refreshAccessTokenAction = createAsyncThunk("userInfo/refreshAccessToken", async (_: void, thunkAPI) => {
    await refreshAccessToken();
    return thunkAPI.dispatch(getCurrentUserAction());
});

interface UpdateUserEmail {
    email: string;
    password: string;
}
export const updateEmailAction = createAsyncThunk("userInfo/updateEmail", async (fields: UpdateUserEmail, thunkAPI) => {
    try {
        await updateEmail(fields.email, fields.password);
        return await thunkAPI.dispatch(getCurrentUserAction());
    } catch (err) {
        return thunkAPI.rejectWithValue(err as AxiosError);
    }
});

const authSlice = createSlice({
    name: "userInfo",
    initialState,
    reducers: {
        resetState() {
            return initialState;
        },
        setSelectedSiteLocationAction: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                session_preferences: {
                    ...state.session_preferences,
                    selected_site_location: action.payload,
                },
            };
        },
        setSelectedCompanyAction: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                session_preferences: {
                    ...state.session_preferences,
                    selected_company: action.payload,
                },
            };
        },
        setUserAction: (state, action: PayloadAction<UserModel>) => {
            const systemMode = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
            const fetchedTheme = action.payload.user_preferences.theme;
            document.documentElement.setAttribute(
                "data-bs-theme",
                fetchedTheme === "system" ? systemMode : fetchedTheme,
            );
            return {
                ...state,
                ...action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserPreferencesAction.fulfilled, (state, action) => {
            return {
                ...state,
                user_preferences: {
                    ...action.payload,
                },
            };
        });
        builder.addCase(getCurrentUserAction.fulfilled, (state, action) => {
            // There are 2 concepts of THEME:
            // First: comes from backend: "light" | "dark" | "system"
            // Second: app theme: "light" | "dark"
            // the app doesn't understand "system", so every time "system" comes back, you need to check system theme with
            // window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"

            // This actually updates theme EVERY TIME getCurrentUser fetches SUCCESFULLY
            const systemMode = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
            const fetchedTheme = action.payload.user_preferences.theme;
            document.documentElement.setAttribute(
                "data-bs-theme",
                fetchedTheme === "system" ? systemMode : fetchedTheme,
            );
            return {
                ...state,
                ...action.payload,
            };
        });
    },
});

export const { resetState, setSelectedSiteLocationAction, setSelectedCompanyAction, setUserAction } = authSlice.actions;

export const logoutAction = createAsyncThunk("userInfo/logout", async (_: void, thunkAPI) => {
    await logoutUser();
    // Auth is set to dark mode by default
    document.documentElement.setAttribute("data-bs-theme", "dark");
    return thunkAPI.dispatch(resetState());
});

export default authSlice.reducer;
