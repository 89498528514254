import { ViewPortModel } from "./models/ViewPortModel";
import { ElementStyleUtil } from "./_ElementStyleUtil";
import { DataUtil } from "./_DataUtil";
import { ElementAnimateUtil } from "./ElementAnimateUtil";
import { getObjectPropertyValueByKey, toJSON } from "./_TypesHelpers";

function getCSS(el: HTMLElement, styleProp: string): string {
    const { defaultView } = el.ownerDocument || document;

    if (!defaultView) {
        return "";
    }

    return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp.replace(/([A-Z])/g, "-$1").toLowerCase());
}

function getCSSVariableValue(variableName: string): string {
    let hex = getComputedStyle(document.documentElement).getPropertyValue(variableName);
    if (hex && hex.length > 0) {
        hex = hex.trim();
    }

    return hex;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getElementActualCss(el: HTMLElement, prop: any, cache: boolean): number {
    let css = "";

    if (!el.getAttribute(`kt-hidden-${prop}`) || cache === false) {
        let value;

        // the element is hidden so:
        // making the el block so we can meassure its height but still be hidden
        css = el.style.cssText;
        // eslint-disable-next-line no-param-reassign
        el.style.cssText = "position: absolute; visibility: hidden; display: block;";

        if (prop === "width") {
            value = el.offsetWidth;
        } else if (prop === "height") {
            value = el.offsetHeight;
        }

        // eslint-disable-next-line no-param-reassign
        el.style.cssText = css;

        // store it in cache
        if (value !== undefined) {
            el.setAttribute(`kt-hidden-${prop}`, value.toString());
            return parseFloat(value.toString());
        }
        return 0;
    }
    // store it in cache
    const attributeValue = el.getAttribute(`kt-hidden-${prop}`);
    if (attributeValue || attributeValue === "0") {
        return parseFloat(attributeValue);
    }

    return 0;
}

function getElementActualHeight(el: HTMLElement): number | undefined {
    return getElementActualCss(el, "height", false);
}

function getElementIndex(element: HTMLElement): number {
    if (element.parentNode) {
        const c = element.parentNode.children;
        for (let i = 0; i < c.length; i += 1) {
            if (c[i] === element) return i;
        }
    }
    return -1;
}

// https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
function getElementMatches(element: HTMLElement, selector: string): boolean {
    const p = Element.prototype;
    const f = p.matches || p.webkitMatchesSelector;

    if (element && element.tagName) {
        return f.call(element, selector);
    }
    return false;
}

function getElementParents(element: Element, selector: string): Array<Element> {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
        Element.prototype.matches = function matcher(s) {
            const matches = (document || this.ownerDocument).querySelectorAll(s);
            let i = matches.length - 1;
            while (i >= 0 && matches.item(i) !== this) {
                i -= 1;
            }
            return i > -1;
        };
    }

    // Set up a parent array
    const parents: Array<Element> = [];

    let el: Element | null = element;

    // Push each parent element to the array
    for (; el && el !== document.body; el = el.parentElement) {
        if (selector) {
            if (el.matches(selector)) {
                parents.push(el);
            }
            // eslint-disable-next-line no-continue
            continue;
        }
        parents.push(el);
    }

    // Return our parent array
    return parents;
}

function getHighestZindex(el: HTMLElement): number | null {
    let bufferNode: Node | null = el as Node;
    let buffer = el;
    while (bufferNode && bufferNode !== document) {
        // Ignore z-index if position is set to a value where z-index is ignored by the browser
        // This makes behavior of this function consistent across browsers
        // WebKit always returns auto if the element is positioned
        const position = buffer.style.getPropertyValue("position");
        if (position === "absolute" || position === "relative" || position === "fixed") {
            // IE returns 0 when zIndex is not specified
            // other browsers return a string
            // we ignore the case of nested elements with an explicit value of 0
            // <div style="z-index: -10;"><div style="z-index: 0;"></div></div>
            const value = parseInt(buffer.style.getPropertyValue("z-index"));
            if (!Number.isNaN(value) && value !== 0) {
                return value;
            }
        }

        bufferNode = bufferNode.parentNode;
        buffer = bufferNode as HTMLElement;
    }
    return null;
}

function getScrollTop(): number {
    return (document.scrollingElement || document.documentElement).scrollTop;
}

// https://developer.mozilla.org/en-US/docs/Web/API/Window/innerWidth
function getViewPort(): ViewPortModel {
    return {
        width: window.innerWidth,
        height: window.innerHeight,
    };
}

function insertAfterElement(el: HTMLElement, referenceNode: HTMLElement): HTMLElement | undefined {
    return referenceNode.parentNode?.insertBefore(el, referenceNode.nextSibling);
}

function isVisibleElement(element: HTMLElement): boolean {
    return !(element.offsetWidth === 0 && element.offsetHeight === 0);
}

// Throttle function: Input as function which needs to be throttled and delay is the time interval in milliseconds
function throttle(givenTimer: number | undefined, func: Function, delay?: number): void {
    // If setTimeout is already scheduled, no need to do anything
    if (givenTimer) {
        return;
    }

    // Schedule a setTimeout after delay seconds
    window.setTimeout(() => {
        func();
    }, delay);
}

function getElementChildren(element: HTMLElement, selector: string): Array<HTMLElement> | null {
    if (!element || !element.childNodes) {
        return null;
    }

    const result: Array<HTMLElement> = [];
    for (let i = 0; i < element.childNodes.length; i += 1) {
        const child = element.childNodes[i];
        // child.nodeType == 1 => Element, Text, Comment, ProcessingInstruction, CDATASection, EntityReference
        if (child.nodeType === 1 && getElementMatches(child as HTMLElement, selector)) {
            result.push(child as HTMLElement);
        }
    }

    return result;
}

function getElementChild(element: HTMLElement, selector: string): HTMLElement | null {
    const children = getElementChildren(element, selector);
    return children ? children[0] : null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function slide(el: HTMLElement, dir: string, givenSpeed: number, callback: any): void {
    if (!el || (dir === "up" && isVisibleElement(el) === false) || (dir === "down" && isVisibleElement(el) === true)) {
        return;
    }

    const speed = givenSpeed || 600;
    const calcHeight = getElementActualHeight(el);
    let calcPaddingTop: number = 0;
    let calcPaddingBottom: number = 0;

    if (ElementStyleUtil.get(el, "padding-top") && DataUtil.get(el, "slide-padding-top") !== true) {
        DataUtil.set(el, "slide-padding-top", ElementStyleUtil.get(el, "padding-top"));
    }

    if (ElementStyleUtil.get(el, "padding-bottom") && DataUtil.has(el, "slide-padding-bottom") !== true) {
        DataUtil.set(el, "slide-padding-bottom", ElementStyleUtil.get(el, "padding-bottom"));
    }

    if (DataUtil.has(el, "slide-padding-top")) {
        const data = DataUtil.get(el, "slide-padding-top") as string;
        calcPaddingTop = parseInt(data as string);
    }

    if (DataUtil.has(el, "slide-padding-bottom")) {
        const data = DataUtil.get(el, "slide-padding-bottom") as string;
        calcPaddingBottom = parseInt(data);
    }

    if (dir === "up") {
        // up
        // eslint-disable-next-line no-param-reassign
        el.style.cssText = "display: block; overflow: hidden;";

        if (calcPaddingTop) {
            ElementAnimateUtil.animate(0, calcPaddingTop, speed, function update(value: number) {
                // eslint-disable-next-line no-param-reassign
                el.style.paddingTop = `${calcPaddingTop - value}px`;
            });
        }

        if (calcPaddingBottom) {
            ElementAnimateUtil.animate(0, calcPaddingBottom, speed, function update(value: number) {
                // eslint-disable-next-line no-param-reassign
                el.style.paddingBottom = `${calcPaddingBottom - value}px`;
            });
        }

        ElementAnimateUtil.animate(
            0,
            calcHeight || 0,
            speed,
            function update(value: number) {
                // eslint-disable-next-line no-param-reassign
                el.style.height = `${(calcHeight || 0) - value}px`;
            },
            function givenCompleteFunc() {
                el.style.height = ""; // eslint-disable-line no-param-reassign
                el.style.display = "none"; // eslint-disable-line no-param-reassign

                if (typeof callback === "function") {
                    callback();
                }
            },
        );
    } else if (dir === "down") {
        // down
        // eslint-disable-next-line no-param-reassign
        el.style.cssText = "display: block; overflow: hidden;";

        if (calcPaddingTop) {
            ElementAnimateUtil.animate(
                0,
                calcPaddingTop,
                speed,
                function update(value: number) {
                    // eslint-disable-next-line no-param-reassign
                    el.style.paddingTop = `${value}px`;
                },
                function givenCompleteFunc() {
                    // eslint-disable-next-line no-param-reassign
                    el.style.paddingTop = "";
                },
            );
        }

        if (calcPaddingBottom) {
            ElementAnimateUtil.animate(
                0,
                calcPaddingBottom,
                speed,
                function update(value: number) {
                    // eslint-disable-next-line no-param-reassign
                    el.style.paddingBottom = `${value}px`;
                },
                function givenCompleteFunc() {
                    // eslint-disable-next-line no-param-reassign
                    el.style.paddingBottom = "";
                },
            );
        }

        ElementAnimateUtil.animate(
            0,
            calcHeight || 0,
            speed,
            function update(value: number) {
                // eslint-disable-next-line no-param-reassign
                el.style.height = `${value}px`;
            },
            function givenCompleteFunc() {
                el.style.height = ""; // eslint-disable-line no-param-reassign
                el.style.display = ""; // eslint-disable-line no-param-reassign
                el.style.overflow = ""; // eslint-disable-line no-param-reassign

                if (typeof callback === "function") {
                    callback();
                }
            },
        );
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function slideUp(el: HTMLElement, speed: number, callback: any): void {
    slide(el, "up", speed, callback);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function slideDown(el: HTMLElement, speed: number, callback: any): void {
    slide(el, "down", speed, callback);
}

function getBreakpoint(breakpoint: string): number | string {
    let value: number | string = getCSSVariableValue(`--bs-${breakpoint}`);
    if (value) {
        value = parseInt(value.trim());
    }

    return value;
}

function getAttributeValueByBreakpoint(incomingAttr: string): string | JSON {
    const value = toJSON(incomingAttr);
    if (typeof value !== "object") {
        return incomingAttr;
    }

    const { width } = getViewPort();
    let resultKey;
    let resultBreakpoint = -1;
    let breakpoint;

    /* eslint-disable no-restricted-syntax */
    for (const key in value) {
        if (Object.hasOwn(value, key)) {
            if (key === "default") {
                breakpoint = 0;
            } else {
                breakpoint = getBreakpoint(key) ? +getBreakpoint(key) : parseInt(key);
            }

            if (breakpoint <= width && breakpoint > resultBreakpoint) {
                resultKey = key;
                resultBreakpoint = breakpoint;
            }
        }
    }
    /* eslint-enable */

    return resultKey ? getObjectPropertyValueByKey(value, resultKey) : value;
}

export {
    getBreakpoint,
    getCSS,
    getCSSVariableValue,
    getElementActualCss,
    getElementActualHeight,
    getElementIndex,
    getElementMatches,
    getElementParents,
    getHighestZindex,
    getScrollTop,
    getViewPort,
    insertAfterElement,
    isVisibleElement,
    throttle,
    getElementChildren,
    getElementChild,
    slide,
    slideUp,
    slideDown,
    getAttributeValueByBreakpoint,
};
