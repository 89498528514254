import Highcharts from "highcharts";
import HighchartsBoost from "highcharts/modules/boost";
import Accessibility from "highcharts/modules/accessibility";

HighchartsBoost(Highcharts);

const setupHighcharts = (): void => {
    Accessibility(Highcharts);
    Highcharts.theme = {
        boost: {
            usePreallocated: true,
            pixelRatio: 0,
        },
        colors: ["#006666", "#4DBDB8", "#A8D08D", "#FFCC66", "#FF6666", "#E25826"],
        chart: {
            animation: false,
            backgroundColor: "transparent",
            style: {
                fontFamily: "var(--bs-font-sans-serif)",
            },
        },
        title: {
            text: undefined,
            style: {
                color: "var(--bs-gray-800)",
            },
        },
        tooltip: {
            backgroundColor: "rgba(var(--bs-dark-rgb), 0.7)",
            style: {
                color: "var(--bs-gray-100)",
                fontWeight: "600",
                fontSize: "15px",
            },
            shared: true,
        },
        xAxis: {
            labels: {
                style: {
                    color: "var(--bs-text-dark)",
                    fontWeight: "600",
                    fontSize: "14px",
                },
            },
            lineColor: "var(--bs-gray-300)",
            tickColor: "var(--bs-gray-300)",
            title: {
                text: undefined,
                style: {
                    color: "var(--bs-text-dark)",
                },
            },
        },
        yAxis: {
            gridLineColor: "var(--bs-gray-300)",
            labels: {
                style: {
                    color: "var(--bs-text-dark)",
                    fontWeight: "600",
                    fontSize: "14px",
                },
            },
            title: {
                text: undefined,
                style: {
                    color: "var(--bs-text-dark)",
                    fontWeight: "600",
                    fontSize: "14px",
                },
            },
        },
        plotOptions: {
            series: {
                animation: false,
                dataLabels: {
                    style: {
                        color: "var(--bs-text-dark)",
                        fontSize: "14px",
                        textOutline: "none",
                    },
                },
                borderWidth: 0,
                states: { hover: { lineWidth: 2.5 } },
            },
            bar: {
                dataLabels: {
                    enabled: true,
                },
                borderWidth: 0,
                borderRadius: 0,
            },
            pie: {
                dataLabels: {
                    enabled: false,
                },
                borderRadius: 0,
            },
            line: {
                lineWidth: 2.5,
            },
            area: {
                lineWidth: 2.5,
            },
        },
        legend: {
            backgroundColor: "var(--bs-gray-200)",
            itemStyle: {
                color: "var(--bs-primary)",
                fontSize: "14px",
                fontWeight: "600",
            },
            itemHoverStyle: {
                color: "var(--bs-info)",
            },
            itemHiddenStyle: {
                color: "var(--bs-gray-600)",
            },
            title: {
                style: {},
            },
            verticalAlign: "top",
        },
        exporting: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
    };
    Highcharts.setOptions(Highcharts.theme);
};

export { setupHighcharts };
