import { configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
// import logger from "redux-logger";
import userInfoReducer from "./userInfoReducer";
import configurationTeamsReducer from "./configurationTeamsReducer";
import configurationOperatorsReducer from "./configurationOperatorsReducer";
import configurationDevicesReducer from "./configurationDevicesReducer";
import pageDataReducer from "./pageDataReducer";
import administrationSiteLocationReducer from "./administrationSiteLocationReducer";
import administrationDaqsReducer from "./administrationDaqsReducer";
import administrationIdTagsReducer from "./administrationIdTagsReducer";
import administrationAegisesReducer from "./administrationAegisesReducer";
import administrationUsersReducer from "./administrationUsersReducer";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = configureStore({
    reducer: {
        userInfo: userInfoReducer,
        configurationTeams: configurationTeamsReducer,
        configurationOperators: configurationOperatorsReducer,
        configurationDevices: configurationDevicesReducer,
        pageData: pageDataReducer,
        administrationSiteLocation: administrationSiteLocationReducer,
        administrationDaqs: administrationDaqsReducer,
        administrationIdTags: administrationIdTagsReducer,
        administrationAegises: administrationAegisesReducer,
        administrationUsers: administrationUsersReducer,
    },
    middleware: (getDefaultMiddleware) => {
        const middleware = getDefaultMiddleware({
            serializableCheck: false,
        });
        // if (!import.meta.env.PROD) {
        //     return middleware.concat(logger);
        // }
        return middleware;
    },
    enhancers: (getDefaultEnhancers) => {
        const enhancers = getDefaultEnhancers();
        return enhancers.concat(sentryReduxEnhancer);
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
