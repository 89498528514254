import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
    getOperators,
    getOperator,
    OperatorsInterface,
    OperatorInterface,
} from "../modules/workforce-configuration/operators/_requests";

export interface ConfigurationOperatorsReducerInterface {
    operators: OperatorsInterface;
    currentOperator: OperatorInterface | undefined;
    error: AxiosError | undefined;
    loading: boolean;
}

const initialState: ConfigurationOperatorsReducerInterface = {
    operators: { active: [], inactive: [] },
    currentOperator: undefined,
    error: undefined,
    loading: false,
};

export const getAllOperatorsAction = createAsyncThunk(
    "configurationOperators/getAllOperatorsAction",
    async (obj: { company: string; siteLocation: number }, thunkAPI) => {
        try {
            const result = await getOperators(obj.company, obj.siteLocation);
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

export const getCurrentOperatorAction = createAsyncThunk(
    "configurationOperators/getCurrentOperatorAction",
    async (obj: { company: string; siteLocation: number; operator: number }, thunkAPI) => {
        try {
            const currentTeam = await getOperator(obj.company, obj.siteLocation, obj.operator);
            return currentTeam.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

const configurationOperatorsSlice = createSlice({
    name: "configurationOperators",
    initialState,
    reducers: {
        resetState() {
            return initialState;
        },
        setError: (state, action: PayloadAction<AxiosError>) => {
            return { ...state, error: action.payload };
        },
        setCurrentOperator: (state, action: PayloadAction<OperatorInterface>) => {
            return { ...state, currentOperator: action.payload };
        },
    },
    extraReducers: (builder) => {
        // getAllOperatorsAction
        builder.addCase(getAllOperatorsAction.pending, (state) => {
            return { ...state, operators: initialState.operators, error: undefined, loading: true };
        });
        builder.addCase(getAllOperatorsAction.fulfilled, (state, action) => {
            return { ...state, operators: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getAllOperatorsAction.rejected, (state, action) => {
            return { ...state, operators: initialState.operators, error: action.payload as AxiosError, loading: false };
        });
        // getCurrentOperatorAction
        builder.addCase(getCurrentOperatorAction.pending, (state) => {
            return { ...state, currentOperator: undefined, error: undefined, loading: true };
        });
        builder.addCase(getCurrentOperatorAction.fulfilled, (state, action) => {
            return { ...state, currentOperator: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getCurrentOperatorAction.rejected, (state, action) => {
            return { ...state, currentOperator: undefined, error: action.payload as AxiosError, loading: false };
        });
    },
});

export const { resetState, setError, setCurrentOperator } = configurationOperatorsSlice.actions;

export default configurationOperatorsSlice.reducer;
