import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
    getTeams,
    getTeam,
    restoreTeam,
    assignOperators,
    GetTeamsInterface,
    TeamDetails,
    unassignOperators,
} from "../modules/workforce-configuration/teams/_requests";

export interface ConfigurationTeamsReducerInterface {
    teams: GetTeamsInterface;
    currentTeam: TeamDetails | undefined;
    error: AxiosError | undefined;
    loading: boolean;
}

const initialState: ConfigurationTeamsReducerInterface = {
    teams: { active: [], inactive: [] },
    currentTeam: undefined,
    error: undefined,
    loading: false,
};

export const getAllTeamsAction = createAsyncThunk(
    "configurationTeams/getAllTeams",
    async (obj: { company: string; siteLocation: number }, thunkAPI) => {
        try {
            const result = await getTeams(obj.company, obj.siteLocation);
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

export const getCurrentTeamAction = createAsyncThunk(
    "configurationTeams/getCurrentTeam",
    async (obj: { company: string; siteLocation: number; team_id: number }, thunkAPI) => {
        try {
            const currentTeam = await getTeam(obj.company, obj.siteLocation, obj.team_id);
            return currentTeam.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

export const restoreCurrentTeamAction = createAsyncThunk(
    "configurationTeams/restoreCurrentTeamAction",
    async (obj: { company: string; siteLocation: number; team_id: number }, thunkAPI) => {
        try {
            const currentTeam = await restoreTeam(obj.company, obj.siteLocation, obj.team_id);
            return currentTeam.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

interface OperatorsToTeamInterface {
    company: string;
    siteLocation: number;
    team_id: number;
    operator_ids: number[];
}
export const assignToCurrentTeamAction = createAsyncThunk(
    "configurationTeams/assignToCurrentTeamAction",
    async (obj: OperatorsToTeamInterface, thunkAPI) => {
        try {
            const currentTeam = await assignOperators(obj.company, obj.siteLocation, obj.team_id, obj.operator_ids);
            return currentTeam.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

export const unassignFromCurrentTeamAction = createAsyncThunk(
    "configurationTeams/unassignOneCurrentTeamAction",
    async (obj: OperatorsToTeamInterface, thunkAPI) => {
        try {
            const currentTeam = await unassignOperators(obj.company, obj.siteLocation, obj.team_id, obj.operator_ids);
            return currentTeam.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

const configurationTeamsSlice = createSlice({
    name: "configurationTeams",
    initialState,
    reducers: {
        resetState() {
            return initialState;
        },
        setError: (state, action: PayloadAction<AxiosError>) => {
            return { ...state, error: action.payload };
        },
        setCurrentTeam: (state, action: PayloadAction<TeamDetails>) => {
            return { ...state, currentTeam: action.payload };
        },
    },
    extraReducers: (builder) => {
        // getAllTeamsAction
        builder.addCase(getAllTeamsAction.pending, (state) => {
            return { ...state, teams: initialState.teams, error: undefined, loading: true };
        });
        builder.addCase(getAllTeamsAction.fulfilled, (state, action) => {
            return { ...state, teams: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getAllTeamsAction.rejected, (state, action) => {
            return { ...state, teams: initialState.teams, error: action.payload as AxiosError, loading: false };
        });
        // getCurrentTeamAction
        builder.addCase(getCurrentTeamAction.pending, (state) => {
            return { ...state, currentTeam: undefined, error: undefined, loading: true };
        });
        builder.addCase(getCurrentTeamAction.fulfilled, (state, action) => {
            return { ...state, currentTeam: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getCurrentTeamAction.rejected, (state, action) => {
            return { ...state, currentTeam: undefined, error: action.payload as AxiosError, loading: false };
        });
        // restoreCurrentTeamAction
        builder.addCase(restoreCurrentTeamAction.pending, (state) => {
            return { ...state, currentTeam: undefined, error: undefined, loading: true };
        });
        builder.addCase(restoreCurrentTeamAction.fulfilled, (state, action) => {
            return { ...state, currentTeam: action.payload, error: undefined, loading: false };
        });
        builder.addCase(restoreCurrentTeamAction.rejected, (state, action) => {
            return { ...state, currentTeam: undefined, error: action.payload as AxiosError, loading: false };
        });
        // assignToCurrentTeamAction
        builder.addCase(assignToCurrentTeamAction.pending, (state) => {
            return { ...state, currentTeam: undefined, error: undefined, loading: true };
        });
        builder.addCase(assignToCurrentTeamAction.fulfilled, (state, action) => {
            return { ...state, currentTeam: action.payload, error: undefined, loading: false };
        });
        builder.addCase(assignToCurrentTeamAction.rejected, (state, action) => {
            return { ...state, currentTeam: undefined, error: action.payload as AxiosError, loading: false };
        });
        // unassignFromCurrentTeamAction
        builder.addCase(unassignFromCurrentTeamAction.pending, (state) => {
            return { ...state, currentTeam: undefined, error: undefined, loading: true };
        });
        builder.addCase(unassignFromCurrentTeamAction.fulfilled, (state, action) => {
            return { ...state, currentTeam: action.payload, error: undefined, loading: false };
        });
        builder.addCase(unassignFromCurrentTeamAction.rejected, (state, action) => {
            return { ...state, currentTeam: undefined, error: action.payload as AxiosError, loading: false };
        });
    },
});

export const { resetState, setError, setCurrentTeam } = configurationTeamsSlice.actions;

export default configurationTeamsSlice.reducer;
