import { AuthModel } from "./_models";

const AUTH_LOCAL_STORAGE_KEY = "hs-auth-ls";
const getAuth = (): AuthModel | undefined => {
    if (!localStorage) {
        return undefined;
    }

    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    if (!lsValue) {
        return undefined;
    }

    const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
    if (!auth) {
        return undefined;
    }

    return auth;
};

const setAuth = (auth: AuthModel): void => {
    if (!localStorage) {
        return;
    }

    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
};

const removeAuth = (): void => {
    if (!localStorage) {
        return;
    }

    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
};

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY };
