import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
    getGroups,
    getUser,
    getUsers,
    UserGroupsInterface,
    UserInterface,
} from "../modules/company-administration/users/_requests";

export interface AdministrationUsersReducerInterface {
    users: UserInterface[];
    currentUser: UserInterface | undefined;
    groups: UserGroupsInterface[];
    error: AxiosError | undefined;
    loading: boolean;
}

const initialState: AdministrationUsersReducerInterface = {
    users: [],
    currentUser: undefined,
    groups: [],
    error: undefined,
    loading: false,
};

export const getGroupsAction = createAsyncThunk(
    "administrationUsers/getGroups",
    async (obj: { company: string }, thunkAPI) => {
        try {
            const result = await getGroups(obj.company);
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

export const getUsersAction = createAsyncThunk(
    "administrationUsers/getUsers",
    async (obj: { company: string }, thunkAPI) => {
        try {
            const result = await getUsers(obj.company);
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

export const getUserAction = createAsyncThunk(
    "administrationUsers/getUser",
    async (obj: { company: string; user: number }, thunkAPI) => {
        try {
            const result = await getUser(obj.company, obj.user);
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

const administrationUsersSlice = createSlice({
    name: "administrationUsers",
    initialState,
    reducers: {
        resetState(state) {
            return { ...initialState, groups: state.groups };
        },
        setError: (state, action: PayloadAction<AxiosError>) => {
            return { ...state, error: action.payload };
        },
        setCurrentUser: (state, action: PayloadAction<UserInterface>) => {
            return { ...state, currentUser: action.payload };
        },
    },
    extraReducers: (builder) => {
        // getGroupsAction
        builder.addCase(getGroupsAction.pending, (state) => {
            return { ...state, groups: initialState.groups, error: undefined, loading: true };
        });
        builder.addCase(getGroupsAction.fulfilled, (state, action) => {
            return { ...state, groups: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getGroupsAction.rejected, (state, action) => {
            return { ...state, groups: initialState.groups, error: action.payload as AxiosError, loading: false };
        });
        // getCurrentSiteLocationAction
        builder.addCase(getUserAction.pending, (state) => {
            return { ...state, currentUser: initialState.currentUser, error: undefined, loading: true };
        });
        builder.addCase(getUserAction.fulfilled, (state, action) => {
            return { ...state, currentUser: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getUserAction.rejected, (state, action) => {
            return {
                ...state,
                currentUser: initialState.currentUser,
                error: action.payload as AxiosError,
                loading: false,
            };
        });
        // getAllSiteLocationsAction
        builder.addCase(getUsersAction.pending, (state) => {
            return { ...state, users: initialState.users, error: undefined, loading: true };
        });
        builder.addCase(getUsersAction.fulfilled, (state, action) => {
            return { ...state, users: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getUsersAction.rejected, (state, action) => {
            return {
                ...state,
                users: initialState.users,
                error: action.payload as AxiosError,
                loading: false,
            };
        });
    },
});

export const { resetState, setError, setCurrentUser } = administrationUsersSlice.actions;

export default administrationUsersSlice.reducer;
