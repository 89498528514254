import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
    getAdministrationDaqs,
    getAdministrationDaq,
    AdministrationDaqsInterface,
    AdministrationDaqInterface,
} from "../modules/company-administration/daqs/_requests";

export interface AdministrationDaqsReducerInterface {
    daqs: AdministrationDaqsInterface;
    currentDaq: AdministrationDaqInterface | undefined;
    error: AxiosError | undefined;
    loading: boolean;
}

const initialState: AdministrationDaqsReducerInterface = {
    daqs: {
        active: [],
        inactive: [],
    },
    currentDaq: undefined,
    error: undefined,
    loading: false,
};

export const getAdministrationDaqsAction = createAsyncThunk(
    "administrationDaqs/getAdministrationDaqs",
    async (obj: { company: string }, thunkAPI) => {
        try {
            const result = await getAdministrationDaqs(obj.company);
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

export const getAdministrationDaqAction = createAsyncThunk(
    "administrationDaqs/getAdministrationDaq",
    async (obj: { company: string; daq: number }, thunkAPI) => {
        try {
            const result = await getAdministrationDaq(obj.company, obj.daq);
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

const administrationDaqsSlice = createSlice({
    name: "administrationDaq",
    initialState,
    reducers: {
        resetState() {
            return { ...initialState };
        },
        setError: (state, action: PayloadAction<AxiosError>) => {
            return { ...state, error: action.payload };
        },
        setCurrentDaq: (state, action: PayloadAction<AdministrationDaqInterface>) => {
            return { ...state, currentDaq: action.payload };
        },
    },
    extraReducers: (builder) => {
        // getAdministrationDaqsAction
        builder.addCase(getAdministrationDaqsAction.pending, (state) => {
            return { ...state, daqs: initialState.daqs, error: undefined, loading: true };
        });
        builder.addCase(getAdministrationDaqsAction.fulfilled, (state, action) => {
            return { ...state, daqs: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getAdministrationDaqsAction.rejected, (state, action) => {
            return { ...state, daqs: initialState.daqs, error: action.payload as AxiosError, loading: false };
        });
        // getAdministrationDaqAction
        builder.addCase(getAdministrationDaqAction.pending, (state) => {
            return { ...state, currentDaq: initialState.currentDaq, error: undefined, loading: true };
        });
        builder.addCase(getAdministrationDaqAction.fulfilled, (state, action) => {
            return { ...state, currentDaq: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getAdministrationDaqAction.rejected, (state, action) => {
            return {
                ...state,
                currentDaq: initialState.currentDaq,
                error: action.payload as AxiosError,
                loading: false,
            };
        });
    },
});

export const { resetState, setError, setCurrentDaq } = administrationDaqsSlice.actions;

export default administrationDaqsSlice.reducer;
