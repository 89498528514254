import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
    getTimezones,
    getSiteLocation,
    getAllSiteLocations,
    SiteLocationInterface,
    TimezoneInterface,
} from "../modules/company-administration/site-locations/_requests";

export interface AdministrationSiteLocationsReducerInterface {
    siteLocations: SiteLocationInterface[];
    currentSiteLocation: SiteLocationInterface | undefined;
    timezones: TimezoneInterface;
    error: AxiosError | undefined;
    loading: boolean;
}

const initialState: AdministrationSiteLocationsReducerInterface = {
    siteLocations: [],
    currentSiteLocation: undefined,
    timezones: {},
    error: undefined,
    loading: false,
};

export const getAllTimezonesAction = createAsyncThunk(
    "administrationSiteLocation/getTimezones",
    async (obj: { company: string }, thunkAPI) => {
        try {
            const result = await getTimezones(obj.company);
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

export const getSiteLocationAction = createAsyncThunk(
    "administrationSiteLocation/getSiteLocation",
    async (obj: { company: string; siteLocation: number }, thunkAPI) => {
        try {
            const result = await getSiteLocation(obj.company, obj.siteLocation);
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

export const getAllSiteLocationsAction = createAsyncThunk(
    "administrationSiteLocation/getAllSiteLocations",
    async (obj: { company: string }, thunkAPI) => {
        try {
            const result = await getAllSiteLocations(obj.company);
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

const administrationSiteLocationSlice = createSlice({
    name: "administrationSiteLocation",
    initialState,
    reducers: {
        resetState(state) {
            return { ...initialState, timezones: state.timezones };
        },
        setError: (state, action: PayloadAction<AxiosError>) => {
            return { ...state, error: action.payload };
        },
        setCurrentSiteLocation: (state, action: PayloadAction<SiteLocationInterface>) => {
            return { ...state, currentSiteLocation: action.payload };
        },
    },
    extraReducers: (builder) => {
        // getAllTimezonesAction
        builder.addCase(getAllTimezonesAction.pending, (state) => {
            return { ...state, timezones: initialState.timezones, error: undefined, loading: true };
        });
        builder.addCase(getAllTimezonesAction.fulfilled, (state, action) => {
            return { ...state, timezones: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getAllTimezonesAction.rejected, (state, action) => {
            return { ...state, timezones: initialState.timezones, error: action.payload as AxiosError, loading: false };
        });
        // getCurrentSiteLocationAction
        builder.addCase(getSiteLocationAction.pending, (state) => {
            return { ...state, currentSiteLocation: initialState.currentSiteLocation, error: undefined, loading: true };
        });
        builder.addCase(getSiteLocationAction.fulfilled, (state, action) => {
            return { ...state, currentSiteLocation: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getSiteLocationAction.rejected, (state, action) => {
            return {
                ...state,
                currentSiteLocation: initialState.currentSiteLocation,
                error: action.payload as AxiosError,
                loading: false,
            };
        });
        // getAllSiteLocationsAction
        builder.addCase(getAllSiteLocationsAction.pending, (state) => {
            return { ...state, siteLocations: initialState.siteLocations, error: undefined, loading: true };
        });
        builder.addCase(getAllSiteLocationsAction.fulfilled, (state, action) => {
            return { ...state, siteLocations: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getAllSiteLocationsAction.rejected, (state, action) => {
            return {
                ...state,
                siteLocations: initialState.siteLocations,
                error: action.payload as AxiosError,
                loading: false,
            };
        });
    },
});

export const { resetState, setError, setCurrentSiteLocation } = administrationSiteLocationSlice.actions;

export default administrationSiteLocationSlice.reducer;
