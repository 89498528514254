import axios, { AxiosResponse } from "axios";
import * as authHelper from "./AuthHelpers";

// Forgot Password
export const forgotPassword = (email: string): Promise<AxiosResponse> => {
    return axios.post("/auth/password-reset/", { email });
};
export const validatePasswordResetToken = (token: string): Promise<AxiosResponse> => {
    return axios.post("/auth/password-reset/validate_token/", { token });
};
export const requestNewPassword = (token: string, password: string): Promise<AxiosResponse> => {
    return axios.post("/auth/password-reset/confirm/", { token, password });
};

// Create state for the status of acces token refresh
let isRefreshingState = false;
const setIsRefreshing = (state: boolean): void => {
    isRefreshingState = state;
};
const getIsRefreshing = (): boolean => {
    return isRefreshingState;
};

export interface TokenData {
    access: string;
}

export const refreshAccessToken = (): Promise<TokenData> => {
    return new Promise((resolve, reject) => {
        setIsRefreshing(true);
        axios
            .post("/auth/token/refresh/")
            .then((response: AxiosResponse<TokenData>) => {
                authHelper.setAuth({
                    api_token: response.data.access,
                });
                resolve(response.data);
            })
            .catch((error) => {
                authHelper.removeAuth();
                reject(error);
            })
            .finally(() => {
                setIsRefreshing(false);
            });
    });
};

// Listen for state change on variable isRefreshingState
const awaitTokenRefresh = (): Promise<void> => {
    return new Promise((resolve) => {
        const intervalId = setInterval(() => {
            if (getIsRefreshing() === false) {
                clearInterval(intervalId);
                resolve();
            }
        }, 100);
    });
};

// Function used to refresh access token
export const refreshAccessTokenWrapper = async (): Promise<void> => {
    if (getIsRefreshing() === true) {
        await awaitTokenRefresh();
    } else {
        await refreshAccessToken();
    }
};

// Function used to log in; it returns the access token and sets a cookie
export const getAccessTokenAndCookie = (email: string, password: string): Promise<TokenData> => {
    return new Promise((resolve, reject) => {
        axios
            .post("/auth/token/", { email, password })
            .then((response: AxiosResponse<TokenData>) => {
                authHelper.setAuth({
                    api_token: response.data.access,
                });
                resolve(response.data);
            })
            .catch((error) => {
                authHelper.removeAuth();
                reject(error);
            });
    });
};

// Function used to log out
export const logoutUser = (): Promise<AxiosResponse> => {
    return axios.post("/logout/");
};
