import axios, { AxiosResponse } from "axios";
import { PermissionsInterface } from "../../auth/core/_models";

export interface UserGroupsInterface {
    id: number;
    name: PermissionsInterface;
}

interface UserCompanyInterface {
    company: string;
    is_company_admin: boolean;
    is_external_organisation: boolean;
}

interface UserSLGroup {
    group: number;
    site_location: number;
}

export interface UserInterface {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    user_companies: UserCompanyInterface[];
    user_site_location_groups: UserSLGroup[];
    status: "Active" | "Invited";
    last_login: string;
}

export interface CreateUserInterface {
    email: string;
    first_name: string;
    last_name: string;
    user_companies: {
        is_company_admin: boolean;
        is_external_organisation: boolean;
    };
    user_site_location_groups: {
        group: number;
        site_location: number;
    }[];
}

export interface UpdateUserInterface {
    user_companies?: {
        is_company_admin: boolean;
        is_external_organisation: boolean;
    };
    user_site_location_groups?: {
        group: number;
        site_location: number;
    }[];
}

export const getGroups = (company: string): Promise<AxiosResponse<UserGroupsInterface[]>> => {
    return axios.get(`/companies/${company}/company-administration/groups/`);
};
export const getUsers = (company: string): Promise<AxiosResponse<UserInterface[]>> => {
    return axios.get(`/companies/${company}/company-administration/users/`);
};

export const getUser = (company: string, user: number): Promise<AxiosResponse<UserInterface>> => {
    return axios.get(`/companies/${company}/company-administration/users/${user}/`);
};

export const createUser = (company: string, userObject: CreateUserInterface): Promise<AxiosResponse<UserInterface>> => {
    return axios.post(`/companies/${company}/company-administration/users/`, { ...userObject });
};

export const updateUser = (
    company: string,
    userId: number,
    newUser: UpdateUserInterface,
): Promise<AxiosResponse<UserInterface>> => {
    return axios.put(`/companies/${company}/company-administration/users/${userId}/`, { ...newUser });
};

export const deleteUser = (company: string, userId: number): Promise<AxiosResponse> => {
    return axios.delete(`/companies/${company}/company-administration/users/${userId}/`);
};
