import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
    getDaqs,
    getDaq,
    getAegises,
    getAegis,
    getIdTags,
    getIdTag,
    DaqsInterface,
    DaqInterface,
    IdTagsInterface,
    IdTagInterface,
    AegisesInterface,
    AegisInterface,
} from "../modules/workforce-configuration/devices/_requests";

export interface ConfigurationDevicesReducerInterface {
    daqs: DaqsInterface;
    currentDaq: undefined | DaqInterface;
    idTags: IdTagsInterface;
    currentIdTag: undefined | IdTagInterface;
    aegises: AegisesInterface;
    currentAegis: undefined | AegisInterface;
    error: AxiosError | undefined;
    loading: boolean;
}

const initialState: ConfigurationDevicesReducerInterface = {
    daqs: { active: [], inactive: [] },
    currentDaq: undefined,
    idTags: { active: [], inactive: [] },
    currentIdTag: undefined,
    aegises: { active: [], inactive: [] },
    currentAegis: undefined,
    error: undefined,
    loading: false,
};

export const getDaqsAction = createAsyncThunk(
    "configurationDevices/getDaqsAction",
    async (obj: { company: string; siteLocation: number }, thunkAPI) => {
        try {
            const result = await getDaqs(obj.company, obj.siteLocation);
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

export const getDaqAction = createAsyncThunk(
    "configurationDevices/getDaqAction",
    async (obj: { company: string; siteLocation: number; daq: number }, thunkAPI) => {
        try {
            const result = await getDaq(obj.company, obj.siteLocation, obj.daq);
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

export const getAegisesAction = createAsyncThunk(
    "configurationDevices/getAegisesAction",
    async (obj: { company: string; siteLocation: number }, thunkAPI) => {
        try {
            const result = await getAegises(obj.company, obj.siteLocation);
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

export const getAegisAction = createAsyncThunk(
    "configurationDevices/getAegisAction",
    async (obj: { company: string; siteLocation: number; aegis: number }, thunkAPI) => {
        try {
            const result = await getAegis(obj.company, obj.siteLocation, obj.aegis);
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

export const getIdTagsAction = createAsyncThunk(
    "configurationDevices/getIdTagsAction",
    async (obj: { company: string; siteLocation: number }, thunkAPI) => {
        try {
            const result = await getIdTags(obj.company, obj.siteLocation);
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

export const getIdTagAction = createAsyncThunk(
    "configurationDevices/getIdTagAction",
    async (obj: { company: string; siteLocation: number; tag: number }, thunkAPI) => {
        try {
            const result = await getIdTag(obj.company, obj.siteLocation, obj.tag);
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

const configurationDevicesSlice = createSlice({
    name: "configurationDevices",
    initialState,
    reducers: {
        resetState() {
            return initialState;
        },
        setError: (state, action: PayloadAction<AxiosError>) => {
            return { ...state, error: action.payload };
        },
        setCurrentAegis: (state, action: PayloadAction<AegisInterface>) => {
            return { ...state, currentAegis: action.payload };
        },
        setCurrentDaq: (state, action: PayloadAction<DaqInterface>) => {
            return { ...state, currentDaq: action.payload };
        },
        setCurrentIdTag: (state, action: PayloadAction<IdTagInterface>) => {
            return { ...state, currentIdTag: action.payload };
        },
    },
    extraReducers: (builder) => {
        // GET for ALL devices
        // getDaqsAction
        builder.addCase(getDaqsAction.pending, (state) => {
            return { ...state, daqs: initialState.daqs, error: undefined, loading: true };
        });
        builder.addCase(getDaqsAction.fulfilled, (state, action) => {
            return { ...state, daqs: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getDaqsAction.rejected, (state, action) => {
            return { ...state, daqs: initialState.daqs, error: action.payload as AxiosError, loading: false };
        });
        // getAegisesAction
        builder.addCase(getAegisesAction.pending, (state) => {
            return { ...state, aegises: initialState.aegises, error: undefined, loading: true };
        });
        builder.addCase(getAegisesAction.fulfilled, (state, action) => {
            return { ...state, aegises: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getAegisesAction.rejected, (state, action) => {
            return { ...state, aegises: initialState.aegises, error: action.payload as AxiosError, loading: false };
        });
        // getIdTagsAction
        builder.addCase(getIdTagsAction.pending, (state) => {
            return { ...state, idTags: initialState.idTags, error: undefined, loading: true };
        });
        builder.addCase(getIdTagsAction.fulfilled, (state, action) => {
            return { ...state, idTags: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getIdTagsAction.rejected, (state, action) => {
            return { ...state, idTags: initialState.idTags, error: action.payload as AxiosError, loading: false };
        });
        // GET for ONE device
        // getDaqAction
        builder.addCase(getDaqAction.pending, (state) => {
            return { ...state, currentDaq: initialState.currentDaq, error: undefined, loading: true };
        });
        builder.addCase(getDaqAction.fulfilled, (state, action) => {
            return { ...state, currentDaq: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getDaqAction.rejected, (state, action) => {
            return {
                ...state,
                currentDaq: initialState.currentDaq,
                error: action.payload as AxiosError,
                loading: false,
            };
        });
        // getAegisAction
        builder.addCase(getAegisAction.pending, (state) => {
            return { ...state, currentAegis: initialState.currentAegis, error: undefined, loading: true };
        });
        builder.addCase(getAegisAction.fulfilled, (state, action) => {
            return { ...state, currentAegis: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getAegisAction.rejected, (state, action) => {
            return {
                ...state,
                currentAegis: initialState.currentAegis,
                error: action.payload as AxiosError,
                loading: false,
            };
        });
        // getIdTagAction
        builder.addCase(getIdTagAction.pending, (state) => {
            return { ...state, currentIdTag: initialState.currentIdTag, error: undefined, loading: true };
        });
        builder.addCase(getIdTagAction.fulfilled, (state, action) => {
            return { ...state, currentIdTag: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getIdTagAction.rejected, (state, action) => {
            return {
                ...state,
                currentIdTag: initialState.currentIdTag,
                error: action.payload as AxiosError,
                loading: false,
            };
        });
    },
});

export const { resetState, setError, setCurrentAegis, setCurrentIdTag, setCurrentDaq } =
    configurationDevicesSlice.actions;

export default configurationDevicesSlice.reducer;
