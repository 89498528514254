import axios, { AxiosResponse } from "axios";
import { UserModel, UserPreferencesInterface, UserPreferencesUpdateInterface } from "../auth/core/_models";

export const getUser = (): Promise<UserModel> => {
    return new Promise((resolve, reject) => {
        axios
            .get("/user-details/")
            .then((response: AxiosResponse<UserModel>) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getUserPreferences = (): Promise<UserPreferencesInterface> => {
    return new Promise((resolve, reject) => {
        axios
            .get("/user-details/preferences/")
            .then((response: AxiosResponse<UserPreferencesInterface>) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const updateUserDetails = (first_name: string, last_name: string): Promise<AxiosResponse<UserModel>> => {
    return axios.put("/user-details/", { first_name, last_name });
};

export const updateUserPreferences = (
    user_preferences: UserPreferencesUpdateInterface,
): Promise<AxiosResponse<UserModel>> => {
    return axios.put("/user-details/", { user_preferences });
};

export const updateEmail = (email: string, password: string): Promise<AxiosResponse> => {
    return axios.put("/user-details/update-email/", { email, password });
};

export const updatePassword = (current_password: string, new_password: string): Promise<AxiosResponse> => {
    return axios.put("/user-details/update-password/", { current_password, new_password });
};
