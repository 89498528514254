import { FC, lazy } from "react";
import * as Sentry from "@sentry/react";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import SuspensedView from "../components/SuspensedView";
import ErrorBoundaryFallback from "../components/ErrorBoundaryFallback";
import { selectUserEmail } from "../redux/selectors";
import CookieConsentElements from "../components/CookieConsentElements";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App = lazy(() => import("../App"));
const AuthPage = lazy(() => import("../modules/auth/AuthPage"));
const PrivateRoutes = lazy(() => import("./PrivateRoutes"));

const AppRoutesBase: FC = () => {
    const currentUserEmail = useAppSelector(selectUserEmail);

    return (
        <BrowserRouter>
            <ToastContainer
                position="bottom-right"
                autoClose={3500}
                closeOnClick
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
                theme="dark"
            />
            <CookieConsentElements />
            <SentryRoutes>
                <Route element={<App />}>
                    {currentUserEmail === "" ? (
                        <>
                            <Route
                                path="auth/*"
                                element={
                                    <SuspensedView>
                                        <AuthPage />
                                    </SuspensedView>
                                }
                            />
                            <Route path="*" element={<Navigate to="/auth/login" />} />
                        </>
                    ) : (
                        <>
                            <Route
                                path="/*"
                                element={
                                    <SuspensedView>
                                        <PrivateRoutes />
                                    </SuspensedView>
                                }
                            />
                        </>
                    )}
                </Route>
            </SentryRoutes>
        </BrowserRouter>
    );
};

const AppRoutes = Sentry.withProfiler(AppRoutesBase);

export default Sentry.withErrorBoundary(AppRoutes, { fallback: <ErrorBoundaryFallback />, showDialog: true });
