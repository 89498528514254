import axios, { AxiosResponse } from "axios";

export interface BasicTeamInterface {
    id: number;
    name: string;
    created: string;
    modified: string;
    deleted: string | null;
    site_location: number;
}

export interface TeamDetails extends BasicTeamInterface {
    operators: OperatorInterface[];
}

export interface TeamsDetails extends BasicTeamInterface {
    no_of_operators: number;
}

export interface GetTeamsInterface {
    active: TeamsDetails[];
    inactive: BasicTeamInterface[];
}

export interface OperatorInterface {
    id: number;
    badge_id: string;
    team_id: string;
}
export const getTeams = (company: string, siteLocation: number): Promise<AxiosResponse<GetTeamsInterface>> => {
    return axios.get(`/companies/${company}/site-locations/${siteLocation}/workforce-configuration/teams/`);
};

export const getTeam = (company: string, siteLocation: number, team: number): Promise<AxiosResponse<TeamDetails>> => {
    return axios.get(`/companies/${company}/site-locations/${siteLocation}/workforce-configuration/teams/${team}/`);
};

export const createTeam = (
    company: string,
    siteLocation: number,
    name: string,
): Promise<AxiosResponse<TeamDetails>> => {
    return axios.post(`/companies/${company}/site-locations/${siteLocation}/workforce-configuration/teams/`, { name });
};

export const editTeam = (
    company: string,
    siteLocation: number,
    team: number,
    field_updates: object,
): Promise<AxiosResponse<TeamDetails>> => {
    return axios.put(
        `/companies/${company}/site-locations/${siteLocation}/workforce-configuration/teams/${team}/`,
        field_updates,
    );
};

export const deleteTeam = (
    company: string,
    siteLocation: number,
    team: number,
): Promise<AxiosResponse<TeamDetails>> => {
    return axios.put(`/companies/${company}/site-locations/${siteLocation}/workforce-configuration/teams/${team}/`, {
        team_status: "deleted",
    });
};

export const restoreTeam = (
    company: string,
    siteLocation: number,
    team: number,
): Promise<AxiosResponse<TeamDetails>> => {
    return axios.put(`/companies/${company}/site-locations/${siteLocation}/workforce-configuration/teams/${team}/`, {
        team_status: "restored",
    });
};

export const assignOperators = (
    company: string,
    siteLocation: number,
    team: number,
    operator_ids: number[],
): Promise<AxiosResponse<TeamDetails>> => {
    return axios.put(
        `/companies/${company}/site-locations/${siteLocation}/workforce-configuration/teams/${team}/operators/`,
        { operator_ids },
    );
};

export const unassignOperators = (
    company: string,
    siteLocation: number,
    team: number,
    operator_ids: number[],
): Promise<AxiosResponse<TeamDetails>> => {
    return axios.delete(
        `/companies/${company}/site-locations/${siteLocation}/workforce-configuration/teams/${team}/operators/`,
        { data: { operator_ids } },
    );
};
