import { AxiosInstance, AxiosError, InternalAxiosRequestConfig } from "axios";
import { getAuth } from "./AuthHelpers";
import { refreshAccessTokenWrapper } from "./_requests";

function setupAxios(axios: AxiosInstance): void {
    const host = import.meta.env.VITE_BASE_URL_FOR_RETRIEVAL;
    const useSecure = !!import.meta.env.VITE_USE_SECURE;

    /* eslint-disable no-param-reassign */
    axios.defaults.baseURL = `${useSecure ? "https" : "http"}://${host}`;
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.withCredentials = true;
    axios.defaults.paramsSerializer = {
        indexes: null,
    };
    /* eslint-enable no-param-reassign */

    // Add a request interceptor
    axios.interceptors.request.use(
        function onRequest(config: InternalAxiosRequestConfig) {
            // Do something before request is sent
            const auth = getAuth();
            if (auth && auth.api_token) {
                /* eslint-disable no-param-reassign */
                config.headers = config.headers ?? {};
                config.headers.Authorization = `Bearer ${auth.api_token}`;
                /* eslint-enable no-param-reassign */
            }

            return config;
        },
        function onRequestError(error) {
            // Do something with request error
            return Promise.reject(error);
        },
    );

    axios.interceptors.response.use(
        function onResponse(response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        async function onResponseError(error: AxiosError) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            const originalRequest = error.config;

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const responseData: any = error.response?.data;

            if (error.response?.status === 401 && responseData.detail === "Given token not valid for any token type") {
                try {
                    await refreshAccessTokenWrapper();
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    return await axios(originalRequest!);
                } catch (err) {
                    window.location.reload();
                }
            }

            if (
                error.response?.status === 401 &&
                responseData.detail === "Authentication credentials were not provided."
            ) {
                window.location.reload();
            }

            return Promise.reject(error);
        },
    );
}

export { setupAxios };
