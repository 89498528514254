import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PageLink {
    title: string;
    path: string;
}

export interface PageDataInterface {
    pageTitle: string;
    pageBreadcrumbs: PageLink[];
    documentTitle: string;
}

const initialState: PageDataInterface = {
    pageTitle: "",
    pageBreadcrumbs: [],
    documentTitle: "HAV-Sentry",
};

const configurationTeamsSlice = createSlice({
    name: "pageData",
    initialState,
    reducers: {
        resetPageDataState() {
            document.title = "HAV-Sentry";
            return initialState;
        },
        setPageTitle: (state, action: PayloadAction<string>) => {
            return { ...state, pageTitle: action.payload };
        },
        setPageBreadcrumbs: (state, action: PayloadAction<PageLink[]>) => {
            return { ...state, pageBreadcrumbs: action.payload };
        },
        setDocumentTitle: (state, action: PayloadAction<string>) => {
            document.title = `${action.payload} | HAV-Sentry`;
            return { ...state, documentTitle: action.payload };
        },
    },
});

export const { resetPageDataState, setPageTitle, setPageBreadcrumbs, setDocumentTitle } =
    configurationTeamsSlice.actions;

export default configurationTeamsSlice.reducer;
