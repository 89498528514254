import axios, { AxiosResponse } from "axios";

export interface AdministrationIdTagInterface {
    id: number;
    company: string;
    created: string;
    deleted: string | null;
    mac_address: number;
    modified: string;
    serial_no: string;
    site_location: number | null;
    site_location_name: string | null;
    operator: number | null;
    operator_badge_id: string | null;
}

export interface AdministrationIdTagsInterface {
    active: AdministrationIdTagInterface[];
    inactive: AdministrationIdTagInterface[];
}

export const getAdministrationIdTags = (company: string): Promise<AxiosResponse<AdministrationIdTagsInterface>> => {
    return axios.get(`/companies/${company}/company-administration/id-tags/`);
};

export const getAdministrationIdTag = (
    company: string,
    tag: number,
): Promise<AxiosResponse<AdministrationIdTagInterface>> => {
    return axios.get(`/companies/${company}/company-administration/id-tags/${tag}/`);
};

export const updateAdministrationIdTag = (
    company: string,
    siteLocation: number,
    tag: number,
): Promise<AxiosResponse<AdministrationIdTagInterface>> => {
    return axios.put(`/companies/${company}/company-administration/id-tags/${tag}/`, { site_location: siteLocation });
};

export const deleteAdministrationIdTag = (
    company: string,
    tag: number,
): Promise<AxiosResponse<AdministrationIdTagInterface>> => {
    return axios.put(`/companies/${company}/company-administration/id-tags/${tag}/`, { id_tag_status: "deleted" });
};

export const restoreAdministrationIdTag = (
    company: string,
    tag: number,
): Promise<AxiosResponse<AdministrationIdTagInterface>> => {
    return axios.put(`/companies/${company}/company-administration/id-tags/${tag}/`, { id_tag_status: "restored" });
};
