import axios, { AxiosResponse } from "axios";

// ID tags
export interface IdTagInterface {
    id: number;
    created: string;
    modified: string;
    deleted: string | null;
    mac_address: string;
    serial_no: string;
    company: string;
    site_location: number;
    operator: number | null;
    operator_badge_id: string | null;
}

export interface IdTagsInterface {
    active: IdTagInterface[];
    inactive: IdTagInterface[];
}

interface UnassignedIdTagInterface {
    id: number;
    serial_no: string;
}

export const getUnassignedIdTags = (
    company: string,
    siteLocation: number,
): Promise<AxiosResponse<{ id_tags: UnassignedIdTagInterface[] }>> => {
    return axios.get(
        `/companies/${company}/site-locations/${siteLocation}/workforce-configuration/id-tags/unassigned/`,
    );
};

export const getIdTags = (company: string, siteLocation: number): Promise<AxiosResponse<IdTagsInterface>> => {
    return axios.get(`/companies/${company}/site-locations/${siteLocation}/workforce-configuration/id-tags/`);
};

export const getIdTag = (
    company: string,
    siteLocation: number,
    tag: number,
): Promise<AxiosResponse<IdTagInterface>> => {
    return axios.get(`/companies/${company}/site-locations/${siteLocation}/workforce-configuration/id-tags/${tag}/`);
};

export const deleteIdTag = (
    company: string,
    siteLocation: number,
    tag: number,
): Promise<AxiosResponse<IdTagInterface>> => {
    return axios.put(`/companies/${company}/site-locations/${siteLocation}/workforce-configuration/id-tags/${tag}/`, {
        id_tag_status: "deleted",
    });
};

export const restoreIdTag = (
    company: string,
    siteLocation: number,
    tag: number,
): Promise<AxiosResponse<IdTagInterface>> => {
    return axios.put(`/companies/${company}/site-locations/${siteLocation}/workforce-configuration/id-tags/${tag}/`, {
        id_tag_status: "restored",
    });
};

// Aegises
export interface AegisInterface {
    id: number;
    created: string;
    modified: string;
    deleted: string | null;
    mac_address: string;
    serial_no: string;
    company: string;
    site_location: number;
}

export interface AegisesInterface {
    active: AegisInterface[];
    inactive: AegisInterface[];
}

export const getAegises = (company: string, siteLocation: number): Promise<AxiosResponse<AegisesInterface>> => {
    return axios.get(`/companies/${company}/site-locations/${siteLocation}/workforce-configuration/aegises/`);
};

export const getAegis = (
    company: string,
    siteLocation: number,
    aegis: number,
): Promise<AxiosResponse<AegisInterface>> => {
    return axios.get(`/companies/${company}/site-locations/${siteLocation}/workforce-configuration/aegises/${aegis}/`);
};

export const deleteAegis = (
    company: string,
    siteLocation: number,
    aegis: number,
): Promise<AxiosResponse<AegisInterface>> => {
    return axios.put(`/companies/${company}/site-locations/${siteLocation}/workforce-configuration/aegises/${aegis}/`, {
        aegis_status: "deleted",
    });
};

export const restoreAegis = (
    company: string,
    siteLocation: number,
    aegis: number,
): Promise<AxiosResponse<AegisInterface>> => {
    return axios.put(`/companies/${company}/site-locations/${siteLocation}/workforce-configuration/aegises/${aegis}/`, {
        aegis_status: "restored",
    });
};

// Docking Stations
export interface DaqInterface {
    id: number;
    created: string;
    modified: string;
    deleted: string | null;
    mac_address: string;
    serial_no: string;
    company: string;
    site_location: number;
}
export interface DaqsInterface {
    active: DaqInterface[];
    inactive: DaqInterface[];
}
export const getDaqs = (company: string, siteLocation: number): Promise<AxiosResponse<DaqsInterface>> => {
    return axios.get(`/companies/${company}/site-locations/${siteLocation}/workforce-configuration/daqs/`);
};

export const getDaq = (company: string, siteLocation: number, daq: number): Promise<AxiosResponse<DaqInterface>> => {
    return axios.get(`/companies/${company}/site-locations/${siteLocation}/workforce-configuration/daqs/${daq}/`);
};

export const deleteDaq = (company: string, siteLocation: number, daq: number): Promise<AxiosResponse<DaqInterface>> => {
    return axios.put(`/companies/${company}/site-locations/${siteLocation}/workforce-configuration/daqs/${daq}/`, {
        daq_status: "deleted",
    });
};

export const restoreDaq = (
    company: string,
    siteLocation: number,
    daq: number,
): Promise<AxiosResponse<DaqInterface>> => {
    return axios.put(`/companies/${company}/site-locations/${siteLocation}/workforce-configuration/daqs/${daq}/`, {
        daq_status: "restored",
    });
};

export const getWiFiConfig = (
    company: string,
    siteLocation: number,
    object: {
        wifi_ap_name: string;
        wifi_password: string;
        visibility: string;
    },
): Promise<AxiosResponse> => {
    return axios.post(
        `/companies/${company}/site-locations/${siteLocation}/workforce-configuration/daqs/wifi-config/`,
        object,
        {
            responseType: "blob",
        },
    );
};
