import { FC, useRef, useState } from "react";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";

const CookieConsentElements: FC = () => {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const cookieConsentRef = useRef<any>(null);
    const [performanceCheckbox, setPerformanceCheckbox] = useState<boolean>(false);

    return (
        <>
            <CookieConsent
                ref={cookieConsentRef}
                enableDeclineButton
                flipButtons
                disableButtonStyles
                buttonClasses="btn btn-sm btn-primary"
                buttonText="Accept"
                declineButtonClasses="btn btn-sm btn-outline btn-outline-danger ms-3"
                declineButtonText="Reject"
                onAccept={() => {
                    window.location.reload();
                }}
                onDecline={() => {
                    window.location.reload();
                }}
                containerClasses="justify-content-center align-items-center"
                contentStyle={{
                    flex: "initial",
                }}
                style={{
                    backgroundColor: "#fff",
                    color: "#151515",
                }}
            >
                <p className="mb-0 fw-semibold">We are using cookies to give you the best experience on our website.</p>
                <p className="mb-0 fw-semibold">
                    You can find out more about which cookies we are using in the{" "}
                    <span
                        className="link-primary text-decoration-underline cursor-pointer"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_cookie_consent"
                    >
                        cookie settings
                    </span>
                    .
                </p>
            </CookieConsent>
            <div className="modal fade" id="kt_modal_cookie_consent" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title">Privacy Overview</div>
                            <img
                                alt="Logo"
                                className="ms-auto"
                                height={40}
                                src="/media/hav-sentry/logo-horizontal-negative.svg"
                            />
                            <button
                                type="button"
                                className="btn-close ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>

                        <div className="modal-body scroll-y">
                            <div>
                                This website uses cookies so that we can provide you with the best user experience
                                possible. Cookie information is stored in your browser and performs functions such as
                                recognising you when you return to our website and helping our team to understand which
                                sections of the website you find most interesting and useful.
                            </div>
                            <h5 className="mt-5">Strictly Necessary Cookies</h5>
                            <div className="d-flex flex-row">
                                Strictly Necessary Cookie are required for the operation of our Site, Tools and/or
                                Services. These include Cookies that tell us if you’ve consented to the placement of
                                functionality or analytical /performance Cookies, let you log into secure areas of our
                                website, let us authenticate you when you sign-on to use any of our Services, and help
                                us distinguish you from other users of our Site, Tools and/or Services. The
                                functionality of our Services would be affected if these cookies are disabled.
                                <div className="form-check form-switch ms-2">
                                    <input
                                        id="CookieConsentAlwaysActive"
                                        type="checkbox"
                                        role="switch"
                                        className="form-check-input cursor-pointer disabled"
                                        defaultChecked
                                        disabled
                                    />
                                </div>
                            </div>
                            <h5 className="mt-5">Performance</h5>
                            <div className="d-flex flex-row">
                                Performance Cookies enable us to count the number of unique visitors to our Site and/or
                                Tools and to see how users interact with our Site, Tools and/or Services by collecting
                                information such as network requests, text entered and interactions with the website. We
                                use this information to help improve our Site Tools and/or Services and your experience.
                                For example, we can use these Cookies to understand what areas of our website are not
                                being used by users and make improvements.
                                <div className="form-check form-switch ms-2">
                                    <input
                                        id="CookieConsentPerformance"
                                        type="checkbox"
                                        role="switch"
                                        className="form-check-input cursor-pointer disabled"
                                        defaultChecked={getCookieConsentValue() === "true"}
                                        onChange={(e) => {
                                            setPerformanceCheckbox(e.target.checked);
                                        }}
                                    />
                                </div>
                            </div>
                            <h5 className="mt-5">Cookie Policy</h5>
                            <div>More information can be found on our Cookie Policy.</div>
                        </div>

                        <div className="modal-footer justify-content-start">
                            <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                onClick={() => {
                                    cookieConsentRef.current?.accept();
                                }}
                            >
                                Accept All
                            </button>
                            <button
                                type="button"
                                className="btn btn-sm btn-outline btn-outline-danger"
                                onClick={() => {
                                    cookieConsentRef.current?.decline();
                                }}
                            >
                                Reject All
                            </button>
                            <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                onClick={() => {
                                    if (performanceCheckbox) {
                                        cookieConsentRef.current?.accept();
                                    } else {
                                        cookieConsentRef.current?.decline();
                                    }
                                }}
                            >
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CookieConsentElements;
