import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
    getAdministrationIdTag,
    getAdministrationIdTags,
    AdministrationIdTagInterface,
    AdministrationIdTagsInterface,
} from "../modules/company-administration/id-tags/_requests";

export interface AdministrationIdTagsReducerInterface {
    idTags: AdministrationIdTagsInterface;
    currentIdTag: AdministrationIdTagInterface | undefined;
    error: AxiosError | undefined;
    loading: boolean;
}

const initialState: AdministrationIdTagsReducerInterface = {
    idTags: {
        active: [],
        inactive: [],
    },
    currentIdTag: undefined,
    error: undefined,
    loading: false,
};

export const getAdministrationIdTagsAction = createAsyncThunk(
    "administrationIdTags/getAdministrationIdTags",
    async (obj: { company: string }, thunkAPI) => {
        try {
            const result = await getAdministrationIdTags(obj.company);
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

export const getAdministrationIdTagAction = createAsyncThunk(
    "administrationIdTags/getAdministrationIdTag",
    async (obj: { company: string; tagId: number }, thunkAPI) => {
        try {
            const result = await getAdministrationIdTag(obj.company, obj.tagId);
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

const administrationIdTagsSlice = createSlice({
    name: "administrationIdTag",
    initialState,
    reducers: {
        resetState() {
            return { ...initialState };
        },
        setError: (state, action: PayloadAction<AxiosError>) => {
            return { ...state, error: action.payload };
        },
        setCurrentIdTag: (state, action: PayloadAction<AdministrationIdTagInterface>) => {
            return { ...state, currentIdTag: action.payload };
        },
    },
    extraReducers: (builder) => {
        // getAdministrationIdTagsAction
        builder.addCase(getAdministrationIdTagsAction.pending, (state) => {
            return { ...state, idTags: initialState.idTags, error: undefined, loading: true };
        });
        builder.addCase(getAdministrationIdTagsAction.fulfilled, (state, action) => {
            return { ...state, idTags: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getAdministrationIdTagsAction.rejected, (state, action) => {
            return { ...state, idTags: initialState.idTags, error: action.payload as AxiosError, loading: false };
        });
        // getAdministrationIdTagAction
        builder.addCase(getAdministrationIdTagAction.pending, (state) => {
            return { ...state, currentIdTag: initialState.currentIdTag, error: undefined, loading: true };
        });
        builder.addCase(getAdministrationIdTagAction.fulfilled, (state, action) => {
            return { ...state, currentIdTag: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getAdministrationIdTagAction.rejected, (state, action) => {
            return {
                ...state,
                currentIdTag: initialState.currentIdTag,
                error: action.payload as AxiosError,
                loading: false,
            };
        });
    },
});

export const { resetState, setError, setCurrentIdTag } = administrationIdTagsSlice.actions;

export default administrationIdTagsSlice.reducer;
