import axios, { AxiosResponse } from "axios";

export interface AdministrationDaqInterface {
    id: number;
    company: string;
    created: string;
    deleted: string | null;
    mac_address: number;
    modified: string;
    serial_no: string;
    site_location: number | null;
    site_location_name: string | null;
}

export interface AdministrationDaqsInterface {
    active: AdministrationDaqInterface[];
    inactive: AdministrationDaqInterface[];
}

export const getAdministrationDaqs = (company: string): Promise<AxiosResponse<AdministrationDaqsInterface>> => {
    return axios.get(`/companies/${company}/company-administration/daqs/`);
};

export const getAdministrationDaq = (
    company: string,
    daq: number,
): Promise<AxiosResponse<AdministrationDaqInterface>> => {
    return axios.get(`/companies/${company}/company-administration/daqs/${daq}/`);
};

export const updateAdministrationDaq = (
    company: string,
    daq: number,
    siteLocation: number,
): Promise<AxiosResponse<AdministrationDaqInterface>> => {
    return axios.put(`/companies/${company}/company-administration/daqs/${daq}/`, { site_location: siteLocation });
};

export const deleteAdministrationDaq = (
    company: string,
    daq: number,
): Promise<AxiosResponse<AdministrationDaqInterface>> => {
    return axios.put(`/companies/${company}/company-administration/daqs/${daq}/`, { daq_status: "deleted" });
};

export const restoreAdministrationDaq = (
    company: string,
    daq: number,
): Promise<AxiosResponse<AdministrationDaqInterface>> => {
    return axios.put(`/companies/${company}/company-administration/daqs/${daq}/`, { daq_status: "restored" });
};
