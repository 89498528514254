import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faAnglesUp,
    faChartPie,
    faClock,
    faHand,
    faHashtag,
    faCalendarDays,
    faFileContract,
    faGear,
    faBuilding,
    faCube,
    faMicrochip,
    faUser,
    faUserGear,
    faHardDrive,
    faUserTie,
    faUserCircle,
    faEye,
    faEyeSlash,
    faMoon,
    faSun,
    faComputer,
    faTrashCan,
    faPenToSquare,
    faTrashArrowUp,
    faUserSlash,
    faDownload,
    faHandFist,
    faIdBadge,
    faArrowUp,
    faArrowRight,
    faAnglesLeft,
    faAngleLeft,
    faAnglesRight,
    faAngleRight,
    faBars,
    faBook,
    faCircleQuestion,
    faCircleInfo,
    faXmark,
    faNewspaper,
    faPlay,
    faLocationDot,
    faCloudArrowDown,
    faChevronDown,
    faLink,
    faGauge,
    faRuler,
    faChartColumn,
    faHotel,
    faSort,
    faSortUp,
    faSortDown,
    faWifi,
    faChartLine,
    faLifeRing,
    faMagnifyingGlass,
    faPeopleCarryBox,
    faFileArrowDown,
    faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";

const setupFontAwesome = (): void => {
    library.add(
        faAnglesUp,
        faChartPie,
        faClock,
        faHand,
        faHashtag,
        faCalendarDays,
        faFileContract,
        faGear,
        faBuilding,
        faCube,
        faMicrochip,
        faUser,
        faUserGear,
        faHardDrive,
        faUserTie,
        faUserCircle,
        faEye,
        faEyeSlash,
        faMoon,
        faSun,
        faComputer,
        faTrashCan,
        faPenToSquare,
        faTrashArrowUp,
        faUserSlash,
        faDownload,
        faHandFist,
        faIdBadge,
        faArrowUp,
        faArrowRight,
        faAnglesLeft,
        faAngleLeft,
        faAnglesRight,
        faAngleRight,
        faBars,
        faBook,
        faCircleQuestion,
        faCircleInfo,
        faXmark,
        faNewspaper,
        faPlay,
        faLocationDot,
        faCloudArrowDown,
        faChevronDown,
        faLink,
        faGauge,
        faRuler,
        faChartColumn,
        faHotel,
        faSort,
        faSortUp,
        faSortDown,
        faWifi,
        faChartLine,
        faLifeRing,
        faMagnifyingGlass,
        faPeopleCarryBox,
        faFileArrowDown,
        faClockRotateLeft,
    );
};

export { setupFontAwesome };
