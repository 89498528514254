import axios, { AxiosResponse } from "axios";

export interface AdministrationAegisInterface {
    id: number;
    company: string;
    created: string;
    deleted: string | null;
    modified: string;
    mac_address: number;
    serial_no: string;
    site_location: number | null;
    site_location_name: string | null;
}

export interface AdministrationAegisesInterface {
    active: AdministrationAegisInterface[];
    inactive: AdministrationAegisInterface[];
}

export const getAdministrationAegises = (company: string): Promise<AxiosResponse<AdministrationAegisesInterface>> => {
    return axios.get(`/companies/${company}/company-administration/aegises/`);
};

export const getAdministrationAegis = (
    company: string,
    aegis: number,
): Promise<AxiosResponse<AdministrationAegisInterface>> => {
    return axios.get(`/companies/${company}/company-administration/aegises/${aegis}/`);
};

export const updateAdministrationAegis = (
    company: string,
    aegis: number,
    siteLocation: number,
): Promise<AxiosResponse<AdministrationAegisInterface>> => {
    return axios.put(`/companies/${company}/company-administration/aegises/${aegis}/`, { site_location: siteLocation });
};

export const deleteAdministrationAegis = (
    company: string,
    aegis: number,
): Promise<AxiosResponse<AdministrationAegisInterface>> => {
    return axios.put(`/companies/${company}/company-administration/aegises/${aegis}/`, { aegis_status: "deleted" });
};

export const restoreAdministrationAegis = (
    company: string,
    aegis: number,
): Promise<AxiosResponse<AdministrationAegisInterface>> => {
    return axios.put(`/companies/${company}/company-administration/aegises/${aegis}/`, { aegis_status: "restored" });
};
