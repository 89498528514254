import axios, { AxiosResponse } from "axios";

export interface TimezoneInterface {
    [id: string]: {
        timezone: string;
        label: string;
    };
}

interface ExposureThresholdInterface {
    exposure_threshold: {
        a8: {
            eav: number;
            elv: number;
        };
        point: { eav: number; elv: number };
    };
}

export interface SiteLocationInterface {
    id: number;
    name: string;
    company: string;
    created: string;
    modified: string;
    timezone: number;
    site_location_preferences: ExposureThresholdInterface;
}

export const getAllSiteLocations = (company: string): Promise<AxiosResponse<SiteLocationInterface[]>> => {
    return axios.get(`/companies/${company}/company-administration/site-locations/`);
};

export const getSiteLocation = (
    company: string,
    siteLocation: number,
): Promise<AxiosResponse<SiteLocationInterface>> => {
    return axios.get(`/companies/${company}/company-administration/site-locations/${siteLocation}/`);
};

export const getTimezones = (company: string): Promise<AxiosResponse<TimezoneInterface>> => {
    return axios.get(`/companies/${company}/company-administration/timezones/`);
};

export interface CreateSiteLocationInterface {
    timezone: number;
    name: string;
    site_location_preferences: ExposureThresholdInterface;
}
export const createSiteLocation = (
    company: string,
    siteLocationObject: CreateSiteLocationInterface,
): Promise<AxiosResponse<SiteLocationInterface>> => {
    return axios.post(`/companies/${company}/company-administration/site-locations/`, { ...siteLocationObject });
};

export const updateSiteLocation = (
    company: string,
    siteLocation: number,
    siteLocationObject: CreateSiteLocationInterface,
): Promise<AxiosResponse<SiteLocationInterface>> => {
    return axios.put(`/companies/${company}/company-administration/site-locations/${siteLocation}/`, {
        ...siteLocationObject,
    });
};
