import { DOMEventHandlerUtil } from "./_DOMEventHandlerUtil";
import { ElementStyleUtil } from "./_ElementStyleUtil";
import { getElementOffset } from "./ElementUtil";

export class ElementAnimateUtil {
    public static animate(
        from: number,
        to: number,
        duration: number,
        update: Function,
        givenCompleteFunc?: Function,
    ): void {
        /**
         * TinyAnimate.easings
         *  Adapted from jQuery Easing
         */
        const easings = {
            linear(t: number, b: number, c: number, d: number) {
                return (c * t) / d + b;
            },
        };

        // Create mock done() function if necessary
        let complete = givenCompleteFunc;
        if (!complete) {
            complete = function emptyFunc() {};
        }

        // Animation loop
        // let canceled = false;
        const change = to - from;

        update(from);

        // Start animation loop
        const start = window.performance && window.performance.now ? window.performance.now() : +new Date();
        function loop(timestamp: number): void {
            const time = (timestamp || +new Date()) - start;

            if (time >= 0) {
                update(easings.linear(time, from, change, duration));
            }
            if (time >= 0 && time >= duration) {
                update(to);
                if (complete) {
                    complete();
                }
            } else {
                window.requestAnimationFrame(loop);
            }
        }
        window.requestAnimationFrame(loop);
    }

    public static animateClass(element: HTMLElement, animationName: string, callBack?: Function): void {
        const animateClasses = animationName.split(" ");
        animateClasses.forEach((cssClass) => element.classList.add(cssClass));
        DOMEventHandlerUtil.one(element, "animationend", function oneCallBack() {
            animateClasses.forEach((cssClass) => element.classList.remove(cssClass));
        });

        if (callBack) {
            DOMEventHandlerUtil.one(element, "animationend", callBack);
        }
    }

    public static animationDuration(element: HTMLElement, value: string): void {
        ElementStyleUtil.set(element, "animation-duration", value);
    }

    public static scrollTo(element: HTMLElement | null, offset: number, duration: number = 500): void {
        let targetPos = element ? getElementOffset(element).top : 0;
        let scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        if (offset) {
            scrollPos += offset;
            targetPos -= offset;
        }

        const from = scrollPos;
        const to = targetPos;

        ElementAnimateUtil.animate(from, to, duration, function update(value: number) {
            document.documentElement.scrollTop = value;
            // document.body.parentNode.scrollTop = value;
            document.body.scrollTop = value;
        }); // , easing, done
    }

    public static scrollTop(offset: number, duration: number): void {
        ElementAnimateUtil.scrollTo(null, offset, duration);
    }
}
