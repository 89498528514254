import axios, { AxiosResponse } from "axios";

// Operators
export interface OperatorInterface {
    id: number;
    badge_id: string;
    team: number | null;
    team_name: string | null;
    id_tag: number | null;
    id_tag_serial_no: string | null;
    created: string;
    modified: string;
    deleted: string | null;
    site_location: number;
}

export interface OperatorsInterface {
    active: OperatorInterface[];
    inactive: OperatorInterface[];
}

export const getOperators = (company: string, siteLocation: number): Promise<AxiosResponse<OperatorsInterface>> => {
    return axios.get(`/companies/${company}/site-locations/${siteLocation}/workforce-configuration/operators/`);
};

export const getOperator = (
    company: string,
    siteLocation: number,
    operator: number,
): Promise<AxiosResponse<OperatorInterface>> => {
    return axios.get(
        `/companies/${company}/site-locations/${siteLocation}/workforce-configuration/operators/${operator}/`,
    );
};

export const createOperator = (
    company: string,
    siteLocation: number,
    badge_id: string,
): Promise<AxiosResponse<OperatorInterface>> => {
    return axios.post(`/companies/${company}/site-locations/${siteLocation}/workforce-configuration/operators/`, {
        badge_id,
    });
};

export const deleteOperator = (
    company: string,
    siteLocation: number,
    operator: number,
): Promise<AxiosResponse<OperatorInterface>> => {
    return axios.put(
        `/companies/${company}/site-locations/${siteLocation}/workforce-configuration/operators/${operator}/`,
        { operator_status: "deleted" },
    );
};

export const restoreOperator = (
    company: string,
    siteLocation: number,
    operator: number,
): Promise<AxiosResponse<OperatorInterface>> => {
    return axios.put(
        `/companies/${company}/site-locations/${siteLocation}/workforce-configuration/operators/${operator}/`,
        { operator_status: "restored" },
    );
};

export const updateOperator = (
    company: string,
    siteLocation: number,
    operator: number,
    operator_fields: object,
): Promise<AxiosResponse<OperatorInterface>> => {
    return axios.put(
        `/companies/${company}/site-locations/${siteLocation}/workforce-configuration/operators/${operator}/`,
        operator_fields,
    );
};

export interface UnassignedOperatorInterface {
    id: number;
    badge_id: string;
}

export const getUnassignedOperators = (
    company: string,
    siteLocation: number,
): Promise<AxiosResponse<{ operators: UnassignedOperatorInterface[] }>> => {
    return axios.get(
        `/companies/${company}/site-locations/${siteLocation}/workforce-configuration/operators/unassigned/`,
    );
};
